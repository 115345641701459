import { Avatar, Badge, Button, Card, CardBody, CardFooter, CardHeader, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Radio, RadioGroup, Tab, Tabs, Popover, PopoverTrigger, PopoverContent, ListboxItem, Listbox, cn, Chip, User as UserUi, Modal, ModalBody, ModalContent, ScrollShadow, ModalHeader } from "@nextui-org/react";
import { User } from "../models/user";
import * as UserApi from "../network/user_api"
import { HugoLogo } from "./HugoLogo";
import { ReactNode, useEffect, useState } from "react";
import HomeUnfilledIcon from "./Icons/HomeUnfilledIcon";
import HomeFilledIcon from "./Icons/HomeFilledIcon";
import RefreshIcon from "./Icons/RefreshIcon";
import { Route, Routes, redirect, useLocation } from 'react-router-dom';
import LeaderboardFilledIcon from "./Icons/LeaderboardFilledIcon";
import LeaderboardUnfilledIcon from "./Icons/LeaderboardUnfilledIcon";
import QuizzesFilledIcon from "./Icons/QuizzesFilledIcon";
import QuizzesUnfilledIcon from "./Icons/QuizzesUnfilledIcon";
import NotificationFilledIcon from "./Icons/NotificationFilledIcon";
import NotificationUnfilledIcon from "./Icons/NotificationUnfilledIcon";
import SettingsIcon from "./Icons/SettingsIcon";
import LogoutIcon from "./Icons/LogoutIcon";
import SettingsModal from "./SettingsModal"
import InteractiveProfile from "./InteractiveProfile";
import SparklesIcon from "./Icons/SparklesIcon";
import SubjectivesIcon from "./Icons/SubjectivesIcon";
import SubjectivesIconFilled from "./Icons/SubjectivesIconFilled";
import { getUserNotifications } from "../network/user_api";
import DismissNotificationIcon from "./Icons/DismissNotificationIcon";
import ListIcon from "./Icons/ListIcon";
import NotificationModal from "./NotificationModal";
import ManageOrganizationModal from "./ManageOrganizationModal";
import ViewOrganizationModal from "./ViewOrganizationInfoModal";
import CodeIcon from "./Icons/CodeIcon";

interface NavBarLoggedInViewProps {
    user: User,
    onLogoutSuccessful: () => void
}


const NavBarLoggedInView = ({ user, onLogoutSuccessful }: NavBarLoggedInViewProps) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [logoutHovered, setLogoutHovered] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [userOrg, setUserOrg] = useState<any>();
    const [notifications, setNotifications] = useState<any[]>([]);
    const [notificationsLoaded, setNotificationsLoaded] = useState(false);
    const [viewNotification, setViewNotification] = useState<any>();
    const [refreshNotifications, setRefreshNotifications] = useState<Boolean>(false);

    const [viewPastNotifications, setViewPastNotifications] = useState(false);
    const [pastNotificationsLoaded, setPastNotificationsLoaded] = useState(false);
    const [pastNotifications, setPastNotifications] = useState<any[]>([]);

    const [disabledNotifications, setDisabledNotifications] = useState<any[]>([]);

    const [manageOrganizationModalOpen, setManageOrganizationModalOpen] = useState(false);
    const [viewOrganizationModalOpen, setViewOrganizationModalOpen] = useState(false);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    async function getOrganizationInfo() {
        const h = await UserApi.getOrganizationInfo({ orgCode: user.organization });
        

        setUserOrg(h);
    }

    async function getNotifications() {
        setNotificationsLoaded(false)
        const h: any[] = await getUserNotifications();

        setNotifications(h);
        setRefreshNotifications(true);
        setNotificationsLoaded(true);
    }

    async function loadPastNotifications(){
        setPastNotificationsLoaded(false);
        const h: any[] = await UserApi.getAllUserNotifications();

        setPastNotifications(h);
        setPastNotificationsLoaded(true);
    }

    useEffect(() => {
        getOrganizationInfo()
        getNotifications()

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    async function logout() {
        try {
            await UserApi.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.log(error);
        }
    }

    function refreshPage(){ 
        window.location.reload(); 
    }

    const location = useLocation();
    const { pathname } = location;

    return (
        <>
            {manageOrganizationModalOpen && <ManageOrganizationModal
            
            loggedInUser={user}
            onDismiss={() => {
                setManageOrganizationModalOpen(false)
                refreshPage();
            }}
            org={userOrg}

            />}

            {viewOrganizationModalOpen && <ViewOrganizationModal
            loggedInUser={user}
            onDismiss={() => {setViewOrganizationModalOpen(false)}}
            org={userOrg}
            />}
            {viewNotification !== undefined && viewNotification !== null && <NotificationModal notification={viewNotification} onDismiss={function (): void {
                setViewNotification(undefined)
            } } onMarkRead={async () => {
            await UserApi.markNotificationRead({notificationId:viewNotification._id}); 
            console.log(notifications);
            console.log(viewNotification);
            console.log(notifications.filter((v) => {return v !== viewNotification}));
            
            
            
            setNotifications(notifications.filter((v) => {return v !== viewNotification})); await getNotifications()}}/> }

            {viewPastNotifications && pastNotificationsLoaded && <>
            <Modal style={{overflow:'hidden', width:'27rem', margin:'auto', justifyContent:'center'}} isOpen={viewPastNotifications} onClose={() => {setViewPastNotifications(false); setPastNotificationsLoaded(false)}}>
                <ModalContent>
                    <ModalHeader style={{color:'white', padding:'2rem 2rem 0.4rem 2rem', justifyContent:'center', margin:'auto'}}>
                        <p>Notification History</p>
                    </ModalHeader>
                    <Divider className="w-[200px]"/>
                <ModalBody>
                <ScrollShadow className="w-[300px] h-[400px]">
                <Listbox emptyContent={"You haven't received any notifications yet."} style={{maxHeight: '40rem', overflow:'auto', width:'justify-content', scrollbarColor:'#141414', scrollBehavior:'smooth'}}>

                    
                    {pastNotifications.map((notification) => {

                        return (
                            <ListboxItem key={notification.title + " " + notification.message + " " + notification.sender} onClick={() => {setViewNotification(notification) }}>
                            <Card className="max-w-[200px]" style={{marginBottom:'0.3rem'}}>
                                <CardHeader className="flex gap-3">
                                
                                    <div className="flex flex-col">
                                        <p className="text-md">{notification.title}</p>
                                        <p className="text-small text-default-500">{notification.sender}</p>
                                    </div>
                                </CardHeader>
                                <Divider />
                                <CardBody>
                                    <p>{notification.message}</p>
                                </CardBody>
                            </Card>
                            </ListboxItem>

                        )
                    })}
                    
                    </Listbox>
                    </ScrollShadow>
                </ModalBody>
                </ModalContent>
            </Modal>
                
                
            </>}

            {settingsOpen && <SettingsModal loggedInUser={user} onDismiss={() => { setSettingsOpen(false) }} />}
            <Navbar style={{ backgroundColor: "#101010", marginBottom: "1rem", position:'fixed' }}>

                {windowDimensions.width > 1466 && <>
                    <Popover>
                        <PopoverTrigger>
                        <Card style={{ justifySelf: 'start', position: 'fixed', left: '1.3rem', fontSize: 'justify-content', maxWidth: '8rem', maxHeight: '2rem', marginTop: '0.1rem' }}>
                            <CardHeader style={{ alignSelf: 'center', marginTop: '-0.5rem' }}>
                                <div className="flex flex-col">
                                    <p style={{ color: "#E0FBFC", fontSize: 9 }}>{userOrg && userOrg.name}</p>
                                </div>

                            </CardHeader>
                        </Card>
                        </PopoverTrigger>
                        <PopoverContent>
                            <Listbox style={{color:'white'}}>
                                <ListboxItem key={"Organization Info"} onClick={() => {setViewOrganizationModalOpen(true)}}>
                                    Organization Info
                                </ListboxItem>
                                {
                                    userOrg && userOrg.admins.includes(user._id as String) && 
                                    <ListboxItem key={"Manage Organization"} onClick={() => {setManageOrganizationModalOpen(true)}}>
                                        Manage Organization
                                    </ListboxItem>
                                }
                            </Listbox>
                        </PopoverContent>
                    </Popover>
                    <Popover placement="bottom">
                        <PopoverTrigger>
                            <Chip endContent={<SparklesIcon />} color="default" style={{ left: '11rem', position: 'fixed', color: '#E0FBFC', backgroundColor: '#9000B3' }}>Beta</Chip>
                        </PopoverTrigger>
                        <PopoverContent>
                        <p style={{ color: '#E0FBFC', width: '12rem', padding: '0.6rem', justifyContent:'center', margin:'auto' }}>Developed by <u><a rel="noreferrer" target="_blank" href="https://www.haydenkarp.com">Hayden Karp</a></u></p>
                            <Divider className="mb-1 mt-1"/>
                            <p style={{ color: '#E0FBFC', width: '12rem', padding: '0.6rem' }}>This platform is still in a closed Beta, please be patient and submit feedback/bug reports to karphayden@gmail.com</p>
                            <Divider className="mb-1 mt-1"/>
                            <p style={{ color: '#E0FBFC', width: '12rem', padding: '0.6rem', justifyContent:'center', margin:'auto' }}>Release Candidate: Galao 1.8.1 - PROD</p>
                        </PopoverContent>
                    </Popover></>}

                <NavbarBrand>
                    <a href="/">
                    <HugoLogo />
                    </a>
                </NavbarBrand>
                <NavbarContent className="sm:flex gap-4" justify="center" style={{ marginLeft: "4.5rem" }}>
                    <NavbarItem>
                        <Link color="foreground" href="/dashboard" onClick={() => { setSelectedTab(0) }}>
                            {pathname.indexOf("dashboard") !== -1 ? <HomeFilledIcon width="1.5rem" height="1.5rem" /> : <HomeUnfilledIcon width="1.5rem" height="1.5rem" />}

                        </Link>
                    </NavbarItem>
                    {
                        userOrg && userOrg.leaderboardEnabled && <NavbarItem>
                        <Link href="/leaderboard" color="foreground" aria-current="page">
                            {pathname.indexOf("leaderboard") !== -1 ? <LeaderboardFilledIcon width="1.5rem" height="1.5rem" /> : <LeaderboardUnfilledIcon width="1.5rem" height="1.5rem" />}
                        </Link>
                    </NavbarItem>
                    }
                    
                    <NavbarItem >
                        <Link color="foreground" href="/quizzes" >
                            {pathname.indexOf("quizzes") !== -1 ? <QuizzesFilledIcon width="1.5rem" height="1.5rem" /> : <QuizzesUnfilledIcon width="1.5rem" height="1.5rem" />}
                        </Link>

                    </NavbarItem>

                    {false && <NavbarItem>
                        <Link color="foreground" href="/subjectives" >
                            {pathname.indexOf("subjectives") !== -1 ? <SubjectivesIconFilled width="1.5rem" height="1.5rem" /> : <SubjectivesIcon width="1.5rem" height="1.5rem" />}
                        </Link>
                    </NavbarItem>}

                    {(user._id === "66195cacf4b30601717f40e2" || user._id === "66d773341c3c29c65798a730") && <NavbarItem>
                        <Link color="foreground" href="/dev/reports" >
                            {<CodeIcon color="white" width="1.5rem" height="1.5rem"/>}
                        </Link>
                    </NavbarItem>}
                </NavbarContent>
                <NavbarContent justify="end">
                    <NavbarItem className="lg:flex">

                        <Popover placement="bottom">
                            <PopoverTrigger>
                                <div>
                                    <Badge content={notifications.length.toString()} hidden={notifications.length == 0} shape="rectangle" color="danger" showOutline={false}>
                                        <NotificationUnfilledIcon />
                                    </Badge>
                                </div>

                            </PopoverTrigger>

                            <PopoverContent>
                                <div className="flex" style={{width:'200px', justifyContent:'center'}}>
                                <Button isDisabled={viewPastNotifications && !pastNotificationsLoaded} variant="light" onClick={() => {setViewPastNotifications(true); loadPastNotifications();}} isIconOnly style={{color:'white', position:'fixed', top:'5px', left:'20px', width:'0.2rem'}} aria-label="list-all-notifications">
                                    <ListIcon width={'1.5rem'} height={'1.5rem'} />
                                    </Button>
                                    <p style={{ color: '#E0FBFC', width: 'justify-content', maxWidth: '12rem', padding: '0.6rem' }}>Notifications</p>

                                    <Button variant="light" onClick={getNotifications} isIconOnly style={{color:'white', position:'fixed', top:'5px', right:'20px', width:'0.2rem'}}>
                                        <RefreshIcon width={'1rem'} height={'1rem'} />
                                    </Button>
                                    
                                </div>
                                <Divider style={{marginTop:'0.3rem', marginBottom:'0.3rem'}} />
                                <Listbox emptyContent={"No Notifications! Check back later"}>

                                {notifications.map((notification) => {

                                return (
                                    <ListboxItem key={"H"} onClick={() => {setViewNotification(notification) }}>
                                    <Card className="max-w-[200px]" style={{marginBottom:'0.3rem', maxWidth:'200px', overflow:'auto'}} isDisabled={disabledNotifications.includes(notification._id)}>
                                        <CardHeader className="flex gap-3">
                                        
                                            <div className="flex flex-col">
                                                <p className="text-md">{notification.title}</p>
                                                <p className="text-small text-default-500">{notification.sender}</p>
                                            </div>

                                            <Button variant="light" onClick={async () => {await UserApi.markNotificationRead({notificationId:notification._id}); setNotifications(notifications.filter((v) => {return v !== notification})); await getNotifications()}} isIconOnly style={{color:'white', width:'0.2rem', bottom:'10px'}} aria-label="dismiss-notification">
                                                <DismissNotificationIcon width={'1.2rem'} height={'1.2rem'} />
                                            </Button>
                                        </CardHeader>
                                        <Divider />
                                        <CardBody style={{overflow:'hidden', whiteSpace:'no-wrap', textOverflow:'ellipsis', width:'12rem'}}>
                                            <p style={{maxWidth:'200px', textOverflow:'ellipsis'}}>{notification.message.toString().substring(0, 25)}...</p>
                                        </CardBody>

                                        {(notification.type == 'friendRequest' || notification.type == 'joinRequest') && <>
                                        
                                        <CardFooter className="flex gap-4">
                                            <Button color="success" style={{color:'white'}} onClick={async () => {notifications.filter((v) => {return v._id != notification._id}); setDisabledNotifications(disabledNotifications.concat(notification._id)); await UserApi.markNotificationRead({notificationId:notification._id, response:true}); await getNotifications();}}>
                                                Accept
                                            </Button>
                                            <Button color="danger" style={{color:'white'}} onClick={async () => {notifications.filter((v) => {return v._id != notification._id}); setDisabledNotifications(disabledNotifications.concat(notification._id)); await UserApi.markNotificationRead({notificationId:notification._id, response:false}); await getNotifications();}}>
                                                Decline
                                            </Button>
                                        </CardFooter>
                                        
                                        </>}
                                        
                                    </Card>
                                    </ListboxItem>

                                )
                                })}
                                
                                </Listbox>
                            </PopoverContent>
                        </Popover>


                    </NavbarItem>
                    <NavbarItem>

                    </NavbarItem>
                </NavbarContent>

                <InteractiveProfile loggedInUser={user} content={
                <div>
                    {
                       user.profilePicture !== "" && <img src={user.profilePicture} style={{width: '40px', height: '40px', position:'fixed', zIndex:1, borderRadius:'50px'}} alt=""/>
                    }

                    <Avatar
                        showFallback
                        style={{ display: "inline-block", marginLeft: "auto", marginRight: "2rem", cursor: 'pointer' }}
                        name={user.username}
                        src="" />

                </div>
                
                } contextContent={<>

                        <Button startContent={<SettingsIcon />} color="primary" style={{ color: 'white', marginRight: '2rem' }} onClick={() => { setSettingsOpen(true); }}>
                            Settings
                        </Button>
                        <Button startContent={<LogoutIcon />} color="danger" style={{ color: 'white' }} onClick={() => { logout(); }}>
                            Logout
                        </Button>
                    </>} friendIndicatorPosition={undefined} withStats={true} popoverPosition={"bottom"} isSelf={true} user={user} />





            </Navbar>
        </>

    );

    /*
    <Navbar.Collapse id="basic-navbar-nav">
                   
               <Nav className="me-auto">
                   <Nav.Link href="/dashboard" className="navbarText" style={{marginRight:"1rem"}}>Dashboard</Nav.Link>
                   <Nav.Link href="/leaderboard" className="navbarText" style={{marginRight:"1rem"}}>Leaderboard</Nav.Link>
                   <Nav.Link href="/quizzes" className="navbarText" style={{marginRight:"23rem"}}>Quizzes</Nav.Link>
                   
               </Nav>
               </Navbar.Collapse>

               <Navbar.Collapse className="justify-content-end" style={{color: "#E0FBFC", marginLeft: "3rem"}}>
               <Navbar.Text className="navbarText">
                   Signed in as: {user.username}
               </Navbar.Text>
               
               </Navbar.Collapse>
               <Button style={{marginLeft:"3rem", color:"#E0FBFC", background:"#373E40"}} onClick={logout}>Logout</Button>
               */
}

export default NavBarLoggedInView;